<template>
    <div class="pagination">
        <template v-for="(item, index) in list.links" :key="index">
            <div class="one-btn" 
                :class="[
                    item.active ? 'active' : '',
                    item.url ? '' : 'disabled'
                ]" 
                @click="gotoPage(item, index)"
            >
                <left v-if="index === 0" theme="outline" size="14" fill="#222"></left>
                <right v-else-if="index === list.links.length - 1" theme="outline" size="14" fill="#222"></right>
                <span v-else>{{ item.label }}</span>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Left, Right } from '@icon-park/vue-next';

@Options({
    components: {
        Left, Right
    },
    props: {
        list: {
            type: Object,
            default: () => []
        }
    },
    emits: ['update:list'],
    methods: {
        gotoPage(item: any = {url: ''}, index: number){
            if(item.url){
                this.$api.getNextPage(item.url).then((res: any) => {
                    this.$emit('update:list', res.data)
                })
            }
        },
    }
})

export default class Pagination extends Vue {}
</script>

<style lang="scss" scoped>
.pagination{
    @extend .flex-row-center;
    margin: 15px 0 20px;
    .one-btn{
        @extend .flex-column-center;
        cursor: pointer;
        width: 30px;
        height: 30px;
        margin: 0 5px;
        border-radius: $radius;
        background-color: $white;
        border: 1px solid $border-color;
        &.active{
            border-color: $primary;
            background-color: $primary;
            color: $white;
        }
        &.disabled{
            cursor: not-allowed;
            &,&:hover{
                color: $grey;
                background-color: $grey-light;
                border-color: $grey-light;
            }
        }
        &:hover{
            border-color: $primary;
            background-color: $primary;
            color: $white;
        }
    }
}
</style>