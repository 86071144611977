
import { Options, Vue } from 'vue-class-component';
import { Left, Right } from '@icon-park/vue-next';

@Options({
    components: {
        Left, Right
    },
    props: {
        list: {
            type: Object,
            default: () => []
        }
    },
    emits: ['update:list'],
    methods: {
        gotoPage(item: any = {url: ''}, index: number){
            if(item.url){
                this.$api.getNextPage(item.url).then((res: any) => {
                    this.$emit('update:list', res.data)
                })
            }
        },
    }
})

export default class Pagination extends Vue {}
